import { NicknameSender } from "./senders/nickname-sender";
import { PositionSender } from "./senders/position-sender";
import { FinishedShapesSender, LiveShapeSender } from "./senders/shape-sender";

export function RemoteSynchronizer() {
    return <>
        <NicknameSender />
        <FinishedShapesSender />
        <LiveShapeSender />
        <PositionSender />
    </>
}