import { Point } from "../tools";

type Line = [Point, Point];
export const pointDistance = ([[x1, y1], [x2, y2]]: Line) => {
    return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2))
}

export const hasMoreY = ([[x1, y1], [x2, y2]]: Line) => {
    return Math.abs(y2 - y1) > Math.abs(x2 - x1);
}

export const hasMoreX = (line: Line) => !hasMoreY(line);

export const midPoint1d = (x1: number, x2: number) => {
    return x1 + (x2 - x1) / 2;
}

export const midPoint2d = ([[x1, y1], [x2, y2]]: Line): Point => [
    Math.round(midPoint1d(x1, x2)),
    Math.round(midPoint1d(y1, y2)),
]

// when the last point is close enough to two points ago then drop the middle one
export const spreadPoints = (points: number[], by: number): number[] => {
    if (points.length <= 3*2) return points;

    const [x1, y1] = points.slice(0, 2);
    const [x3, y3] = points.slice(-2);

    return pointDistance([[x1, y1], [x3, y3]]) > by
        ? points
        : points.slice(0, -2*2).concat([x3, y3]);
}