import { useEffect, useState } from "react";
import { timeout } from "../utils/timeout";
import { Avatar } from "./avatar";
import cn from 'classnames';

import { Icon } from "./icon";
import { useRemoteUser, useRemoteUserIds } from "../store/hooks";

const Cursor = function Cursor({ userId }: { userId: string }) {
    const {nickname, position: [x, y] = []} = useRemoteUser(userId)!;
    const [faded, setFaded] = useState(false);

    useEffect(() => {
        setFaded(false);
        return timeout(() => setFaded(true), 15000)
    }, [x, y]);

    return <div className={cn(`absolute flex items-center flex-col nickname-${nickname}`, {
        'transition-opacity': faded,
        'opacity-0': faded,
    })} style={{left: x, top: y, transitionDuration: faded ? '1000ms' : '', zIndex: -10}}>
        <div className='flex flex-row'>
            <Icon ico='session/user/cursor' style={{fontSize: 12}} />
            <Avatar nickname={nickname} size={24} />
        </div>
        <span style={{ fontSize: 8 }}>{nickname}</span>
    </div>
}

export function RemoteUsersCursors() {
    const userIds = useRemoteUserIds();

    return <>
        {userIds.map(id => <Cursor key={id} userId={id} />)}
    </>
}

