import React from 'react';
import { ShapePoly } from '../../tools';
import { fadeColor } from '../../utils/const';

export const Poly = ({ shape, live, faded }: { shape: ShapePoly, live: boolean, faded?: boolean }) => {
    const {
        points: [x1, y1, x2, y2],
        tool: { lineColor, lineWidth }
    } = shape;

    if (!x2 || !y2) return null;

    const color = faded ? fadeColor : lineColor;

    return <rect
        fill={live ? color : 'transparent'}
        fillOpacity={live ? '.02' : '1'}
        stroke={live ? color : color}
        strokeWidth={lineWidth}
        // strokeDasharray={live ? lineWidth*2 : 0}
        x={Math.min(x1, x2)}
        y={Math.min(y1, y2)}
        width={Math.abs(x2 - x1)}
        height={Math.abs(y2 - y1)}
    />
}
