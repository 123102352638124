import { useEffect } from "react";
import { useNickname } from "../../../store/hooks";
import { when } from "../../../utils/when";
import { dispatcher as remoteDispatcher } from "../../../store/remote";

export function NicknameSender() {
    const nickname = useNickname();

    useEffect(() => {
        when(nickname, remoteDispatcher(':message/->send/who', nickname!))
    }, [nickname]);

    return null;
}