import { useState } from 'react';
import { v4 } from 'uuid';
import { dispatcher } from '../../store/main';
import cn from 'classnames';
import { useToolSupportsMenu, useVisMenu } from '../../store/hooks';
import './action-menu.css';
import { when } from '../../utils/when';
import { Icon, iProjectIcons } from '../icon';
import { iMenu } from '../../tools';


interface Props {
    tag: string,
    ico: iProjectIcons,
    desc?: string,
    title?: string,
    style?: any,
    children: any,
    menu: iMenu,
}
export const ActionMenu = ({tag, ico, title, style, menu, desc, children}: Props) => {
    const [id] = useState(v4());
    const vis = useVisMenu();

    const disabled = !useToolSupportsMenu(menu);

    return <div className={cn('action-menu', tag, {'no-title': !title, disabled })} style={style} onClick={() => when(!disabled, dispatcher(':action-menu/set-visible', id))}>
        <div className='button'>
            <Icon ico={ico} className={tag}/>
            {when(desc, <div className='desc'>{desc}</div>)}
        </div>
        <div className='action-panel-container' onClick={e => e.stopPropagation()}>
            <div className={cn('action-panel', {active: vis === id})}>
                <div className='child'>{children}</div>
                {when(title, <div className='title'>{title}</div>)}
            </div>
        </div>
    </div>

}