import { useSettings } from '../../store/hooks'
import { dispatch } from '../../store/main'
import { ActionMenu } from './action-menu'

export const LineWidth = () => {
    const { lineWidth } = useSettings();

    return <ActionMenu menu='lineWidth' ico='menu/tool/line-width' title='Draw Tools' tag='tool'>
        <div>Line Width: {lineWidth}</div>
        <input 
            min={1}
            max={20}
            type='range'
            value={lineWidth}
            style={{width: 250}}
            className='form-range'
            onMouseUp={() => dispatch(':action-menu/hide')}
            onChange={x => dispatch(':config/tools/change-line-width', Number(x.target.value))}
        />
    </ActionMenu>
}