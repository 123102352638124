import React from 'react';
import { ShapeOval } from '../../tools';
import { fadeColor } from '../../utils/const';

export const Oval = ({ shape, live, faded }: { shape: ShapeOval, live: boolean, faded?: boolean }) => {
    const {
        points: [x1, y1, x2, y2],
        tool: { lineColor, lineWidth }
    } = shape;

    if (!x2 || !y2) return null;
    const xLen = Math.abs(x2 - x1);
    const yLen = Math.abs(y2 - y1);

    const color = faded ? fadeColor : lineColor;

    return <ellipse
        fillOpacity={live ? '.02' : '1'}
        fill={live ? color : 'none'}
        stroke={live ? color : color}
        strokeWidth={lineWidth}
        // strokeDasharray={live ? lineWidth*2 : 0}
        cx={x1 + ((x2 - x1) / 2)}
        cy={y1 + ((y2 - y1) / 2)}
        rx={xLen / 2}
        ry={yLen / 2}
    />
}