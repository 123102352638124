import generateName from 'random-animal-name';
import { useEffect, useRef, useState } from 'react';
import { useNickname, useRename } from '../store/hooks';
import { dispatcher } from '../store/main';
import { Avatar } from './avatar';
import { NotifyModal } from './notification-drawer';


export const SelectNameModal = () => {
    const ref = useRef<any>();
    const [gennedName] = useState(generateName())
    const savedNickname = useNickname();
    const [name, setName] = useState<string>(savedNickname || '');
    const rename = useRename();

    const showModal = !savedNickname || rename;

    useEffect(() => {
        if (!ref.current) return;
    }, [ref, showModal])

    if (savedNickname && !rename) return null;

    return <NotifyModal
        primary={[name ? 'Ok' : 'Use Generated', dispatcher(':user/set-nickname', name || gennedName)]}
        deps={[name]}
    >
        <div className='text-center'>
            <h5>Your Display Name</h5>

            <input className='form-input w-full rounded-sm border-highlight border p-1' placeholder={gennedName} value={name} onChange={e => setName(e.target.value)} />
            <div className='flex justify-center pt-4 pb-2'>
                <Avatar size={96} nickname={name || gennedName} />
            </div>
        </div>
    </NotifyModal>
}