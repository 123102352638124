import { Arrows, iTools } from "../../tools";
import { WhiteBoard } from "../store.types";
import { assocIn } from "clj-ports";

export function changeType(state: WhiteBoard, [activeTool]: [activeTool: iTools]): WhiteBoard {
    return assocIn(state, ['settings', 'activeTool'], activeTool);
}

export function changeColor(state: WhiteBoard, [color]: [color: string]): WhiteBoard {
    return assocIn(state, ['settings', 'lineColor'], color);
}

export function changeLineWidth(state: WhiteBoard, [lineWidth]: [width: number]): WhiteBoard {
    return assocIn(state, ['settings', 'lineWidth'], lineWidth);
}

export function changeArrowType(state: WhiteBoard, [arrowType]: [arrowType?: Arrows]): WhiteBoard {
    return assocIn(state, ['settings', 'arrowType'], arrowType)
}