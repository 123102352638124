import { useNickname } from '../store/hooks'
import { dispatch } from '../store/main';
import { Avatar } from './avatar';

export const MyNicknameDisplay = () => {
    const nickName = useNickname();

    if (!nickName) return null;

    const highlightMe = () => dispatch(':user/highlight', 'me');
    const unhighlight = () => dispatch(':user/highlight');

    return <div
        className="absolute top-0.5 right-1.5 cursor-pointer select-none flex items-center"
        onClick={() => dispatch(':user/rename')}
        onMouseOver={highlightMe}
        onMouseOut={unhighlight}
    >
        <i className='fas fa-wifi text-green pr-2'></i> Connected as: {nickName}
        <Avatar size={34} nickname={nickName} className='pl-2' />
    </div>
}