import { useEffect, useState } from "react";
import { useSnapTap, useSnapshots } from "../store/hooks";
import { timeout } from "../utils/timeout";
import { when } from "../utils/when";
import { Icon } from "./icon";
import cn from 'classnames';
import './snapshot-tray.scss'

function ReceivedSnapAnimation() {
    const [vis, setVis] = useState(false);
    const lastSnap = useSnapTap();
    useEffect(() => {
        setVis(true);
        return timeout(() => setVis(false), 300);
    }, [lastSnap]);

    return <div className={cn({
        'snap-animation': true,
        start: vis,
        finish: !vis
    })}>
        <Icon ico='snapshots/animated-camera' />
    </div>
}

export function SnapshotTray() {
    const snaps = useSnapshots();
    const [expanded, setExpanded] = useState(false);

    if (!snaps.length) return null;

    return <div className="snaps z-10 border-highlight">
        <div className="tray-header" onClick={() => setExpanded(!expanded)}>
            <ReceivedSnapAnimation />
            <div className="title">Snapshots</div>
            <div className="expand">
                <Icon className="ico text-highlight pl-2" ico='snapshots/collapse-tray' />
            </div>
        </div>
        {when(expanded, () => <div className="mt-2">
            {snaps.map(({ thumb }, idx) => <div key={`${idx}`} className='snapshot'>
                <img alt='' className='snap-img' src={thumb} />
            </div>)}
        </div>)}
    </div>
}