import { useToolArrowType } from '../../store/hooks'
import { multiDispatcher } from '../../store/main';
import { Icon, iProjectIcons } from '../icon';
import { ActionMenu } from './action-menu'

const conf: Record<string, [iProjectIcons, string]> = {
    end: ['menu/tool/arrow/end', 'End'],
    start: ['menu/tool/arrow/start', 'Start'],
    both: ['menu/tool/arrow/both', 'Both'],
    none: ['menu/tool/arrow/none', 'None'],
}

export const ArrowsMenu = () => {
    const arrow = useToolArrowType();

    const [ico, desc] = conf[arrow || 'none'];

    return <ActionMenu menu='arrowType' title='Arrow Type' tag='arrow' desc={desc} ico={ico}>
        <div className='tools'>
            <div><Icon ico={conf.start[0]} onClick={multiDispatcher([[':config/tools/change-arrow', 'start'], [':action-menu/hide']])}/></div>
            <div><Icon ico={conf.end[0]} onClick={multiDispatcher([[':config/tools/change-arrow', 'end'], [':action-menu/hide']])}/></div>
            <div><Icon ico={conf.both[0]} onClick={multiDispatcher([[':config/tools/change-arrow', 'both'], [':action-menu/hide']])}/></div>
            <div><Icon ico={conf.none[0]} onClick={multiDispatcher([[':config/tools/change-arrow'], [':action-menu/hide']])}/></div>
        </div>
    </ActionMenu>
}
