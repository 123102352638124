import { Shape } from "../tools";
export function partitionShape(size: number): (shape: Shape) => [tailPart: Shape, parts: Shape[]];
export function partitionShape(size: number, {points, ...raw}: Shape): [tailPart: Shape, parts: Shape[]];
export function partitionShape(...params: any[]): any {
    if (params.length === 1) return (shape: any) => partitionShape(params[0], shape) as any;

    const [size2, {points, ...raw}] = params;
    const size = 50;
    if (size % 10) throw new Error('size must be multiple of 10');

    const howManyParts = Math.floor(Math.floor(points.length / 10) / (size / 10));

    const parts = [...new Array(howManyParts)]
        .map((_, chunk) => ({
            ...raw,
            id: `static-chunk-${raw.id}-${chunk}`,
            points: points.slice(Math.max(0, chunk*size), chunk*size + size)
        } as Shape))
        .reduce((chunks, next, idx) =>  chunks.concat(!chunks.length ? [next] : [
            {...raw,
            points: chunks.slice(-1)[0]!.points.slice(-2).concat(next.points.slice(0, 2)),
            id: `static-chunk-${raw.id}-${idx}-connect`} as any,
            next
        ] as Shape[]), [] as Shape[]);

    return [
        {
            ...raw,
            points: points.slice(Math.max(howManyParts*size - 4, 0))
        } as any,
        parts,
    ]
}