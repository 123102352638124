import { WhiteBoard } from "../store.types";
import * as ls from '../../utils/localstore';
import { assoc, dissoc, updateIn } from "clj-ports";

export function setUserLastXy(state: WhiteBoard, lastXY: [x: number, y: number]): WhiteBoard {
    return assoc(state, 'lastXY', lastXY);
}

export function setNickname({rename, ...state}: WhiteBoard, [nickname]: [nickname: string]) {
    ls.set(':user/nickname', nickname);
    return { ...state, nickname };
}

export function userHighlight(state: WhiteBoard, [uid]: [userId?: string|'me']): WhiteBoard {
    return !uid
        ? dissoc(state, 'highlightedUser')
        : assoc(state, 'highlightedUser', uid)
}

export function renameUser(s: WhiteBoard): WhiteBoard {
    return assoc(s, 'rename', true);
}

export function toggleRemoteUserVis(state: WhiteBoard, [id, forceVal]: [id: string, forceVal?: boolean]): WhiteBoard {
    return updateIn(state, ['hiddenRemoteUsers', id], v => forceVal ?? !v)
}