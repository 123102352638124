import { updateIn } from "clj-ports";
import { WhiteBoard } from "../store.types";

export function textKeyDownHandler(s: WhiteBoard, [{ key, ctrlKey, metaKey }]: [evt: KeyboardEvent]) {

    return updateIn(s as any, ['shapes', 'live', 'text'], t => {
        if (key?.length !== 1 && key !== 'Backspace') return t;
        if (ctrlKey || metaKey) return t;

        return key === 'Backspace' ? t.slice(0, -1) : t + key
    })
}