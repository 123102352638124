import { assoc, update } from "clj-ports";
import { Store } from "react-redux-simple-store";
import { Shape } from "../tools";
import { debounce } from "../utils/debounce";
import { iRawCon, rawConnection } from "../webrtc/raw-connection";
import { batch } from 'react-redux';
import { liveShapeHandler, newShapeHandler, removeShapeHandler, pingShapesHashHandler, allShapesHandler, whoHandler, requestSyncHandler, disconnectHandler, lastPositionHandler } from "./events/remote-events-handlers";

const messageTypes = ['live-shape','new-shape','remove-shape','ping-shapes-hash','all-shapes','who','request-sync','disconnect','last-xy', 'multi-message',] as const;
type iMessageTypes = typeof messageTypes[number];

type userSlice<T> = Record<string, T>;
export interface iRemoteUser {
    id: string,
    nickname: string,
    position?: [number, number]
}

export interface iRemoteStore {
    connection?: iRawCon,
    messageQueue: baseMessage<any>[],
    users: userSlice<iRemoteUser>,
    hiddenRemoteUsers: userSlice<boolean>,
    liveShapes: userSlice<Shape | undefined>,
    shapes: userSlice<[ remoteHash: string, shapes: Shape[] ]>,
    requestingSync?: string,
}
const init: iRemoteStore = {
    hiddenRemoteUsers: {},
    liveShapes: {},
    shapes: {},
    users: {},
    messageQueue: [] as baseMessage<any>[],
} as const;

// some helpers to force us to implement any new message types we add
type iSenders = `:message/->send/${iMessageTypes}`;
type iReceivers = `:message/receive/${iMessageTypes}`;
type iEventHandlers = iSenders | iReceivers;

type iStoreEventKeys = Extract<typeof store.actions, iEventHandlers>;
type iPassOrFail = Required<Record<iStoreEventKeys, any>> extends Required<Record<iEventHandlers, any>> ? any : 'Missing implementation of message type handlers';

// interface payloads ;
export type baseMessage<T extends iMessageTypes> = {
    userId: string,
    ts: number,
    type: T,
    payload: {
        'live-shape':       [shape: Shape|undefined];
        'new-shape':        [shapesHash: string, shape: Shape];
        'remove-shape':     [shapesHash: string, shapeIdx: number];
        'ping-shapes-hash': [shapesHash: string];
        'all-shapes':       [shapes: Shape[], userId?: string];
        'who':              [nickname: string];
        'request-sync':     [userId: string];
        'disconnect':       [];
        'last-xy':          [x: number, y: number];
        'multi-message':    [message: baseMessage<any>[]];
    }[T]
}
export type AnyMessage = baseMessage<iMessageTypes>;

// just a helper to make sure we send what receivers want
const queueRemoteMessage = <T extends iMessageTypes>(action: T, payload: baseMessage<T>['payload'], userId?: string) => {
    s.queue(`:message/->send`, action, payload, userId);
}
// most messages we just want to accept what the recieving end wants and send it on over
const messagePassthrough = <T extends iMessageTypes>(action: T, debounceMs?: number) => (_: any, payload: baseMessage<T>['payload']) => {
    if (!debounceMs) queueRemoteMessage(action, payload);
    else debounce(`remote-send-${action}`, () => queueRemoteMessage(action, payload), debounceMs);
}

export const s = new Store('remote', init, ({
    ':session/connect': (state, [sessId]: [sessId: string]) => {
        const con = rawConnection(sessId);
        con.sub(m => s.dispatch(':message/receive', m));
        return assoc(state, 'connection', con);
    },
    ':message/clear-queue': (state) => assoc(state, 'messageQueue', []),
    ':message/->send-direct': (state, [t, p, userId]: [type: iMessageTypes, payload: any, userId?: string]) => {
        state.connection?.sendMessage(state.connection?.makeMessage(t, p, userId));
    },
    ':message/->send': (state, [t, p, userId]: [type: iMessageTypes, payload: any, userId?: string]) => {
        if (userId) {
            s.queue(':message/->send-direct', t, p, userId)
            return;
        }
        debounce('bulk-message-flush', () => s.queue(':message/->send/multi-message'), 20);
        return update(state, 'messageQueue', q => q.concat(state.connection?.makeMessage(t, p, userId)))
    },
    ':message/->send/multi-message': {thunk: async (getState) => {
        const queue = getState().messageQueue;
        s.dispatch(':message/clear-queue');
        getState().connection?.sendMessage(getState().connection?.makeMessage('multi-message', queue));
    }},
    ':message/receive': (_, [m]: [m: AnyMessage]) => {
        (s.queue as any)(`:message/receive/${m.type}`, m as any)
    },

    /*!!! SEND !!!*/
    // todo: add a debounce here
    ':message/->send/live-shape':        messagePassthrough('live-shape', 10),
    ':message/->send/last-xy':           messagePassthrough('last-xy', 10),
    ':message/->send/who':               messagePassthrough('who'),
    ':message/->send/new-shape':         messagePassthrough('new-shape'),
    ':message/->send/disconnect':        (_, []: baseMessage<'disconnect'>['payload']) => {
        s.queue(`:message/->send-direct`, 'disconnect', []);
        return 
    },
    ':message/->send/all-shapes':        (_, [shapes, userId]: baseMessage<'all-shapes'>['payload']) => {
        s.queue(`:message/->send-direct`, 'all-shapes', [shapes], userId);
        return 
    },
    ':message/->send/request-sync':      messagePassthrough('request-sync'),
    ':message/->send/remove-shape':      messagePassthrough('remove-shape'),
    ':message/->send/ping-shapes-hash':  messagePassthrough('ping-shapes-hash'),

    /*!!! Receive !!!*/
    ':message/receive/live-shape':       liveShapeHandler,
    ':message/receive/new-shape':        newShapeHandler,
    ':message/receive/remove-shape':     removeShapeHandler,
    ':message/receive/ping-shapes-hash': pingShapesHashHandler,
    ':message/receive/all-shapes':       allShapesHandler,
    ':message/receive/who':              whoHandler,
    ':message/receive/request-sync':     requestSyncHandler,
    ':message/receive/disconnect':       disconnectHandler,
    ':message/receive/last-xy':          lastPositionHandler,
    ':message/receive/multi-message':    (_, [m]) => {
        // just a passthrough since we need the non thunk for type safety
        s.queue(':message/receive/->multi-message', m.payload)
    },
    ':message/receive/->multi-message':    { thunk: (getState, [messages]: [messages: AnyMessage[]]) => batch(() => {
        messages.forEach(dispatcher(':message/receive'))
    })},

}));

// this will fail to compile if we forget to implement a sender or receiver
((actions: iPassOrFail) => {})(s.actions);

export const dispatch = s.dispatch;
export const dispatcher = s.dispatcher;
export const multiDispatcher = s.multiDispatch;
export const useSelector = s.useSelector;
export const store = s;
// todo: watch hash and beam new changes. 