import { useSettings } from '../../store/hooks';
import { multiDispatcher } from '../../store/main';
import { tools } from '../../tools';
import { fas } from '../../utils/fas';
import { Icon } from '../icon';
import { ActionMenu } from './action-menu';

export const ToolsMenu = () => {
    const settings = useSettings();
    const activeFa = fas[settings.activeTool];
    return <ActionMenu menu='tool' ico={activeFa} title='Draw Tools' tag='tool'>
        <div className='tools'>
            {tools.map(type => <div key={type}><Icon ico={fas[type]} onClick={multiDispatcher([
                [':config/tools/change-type', type],
                [':action-menu/hide']
            ])} /></div>)}
        </div>
    </ActionMenu>
}
