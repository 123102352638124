import React, { useEffect } from 'react';

import './app.css';
import { Canvas } from './components/canvas';
import { NotificationDrawer } from './components/notification-drawer';
import { RemoteUsersTile } from './components/remote-users-tile';
import { SelectNameModal } from './components/select-name-modal';
import { ShapesRecovery } from './components/shapes-recovery';
import { WhenCollaborationConnected } from './components/collaboration/when-connected';
import { SidePanel } from './components/side-panel/side-panel';
import { Welcome } from './components/welcome';
import { MyNicknameDisplay } from './components/my-nickname-display';
import { dispatcher as mainDispatcher } from './store/main';
import { RemoteSynchronizer } from './components/collaboration/remote-synchronizer';
import { RemoteUsersCursors } from './components/remote-cursors';
import { SnapshotTray } from './components/snapshot-tray';
import { MismatchHashResync } from './components/collaboration/senders/mismatch-hash-resync';
import { CloseConnectionNotifier } from './components/collaboration/senders/close-connection-notifier';

function App() {
  useEffect(function keyDownDispatcher() {
      const keydown = mainDispatcher(':keyboard/keydown')
      document.addEventListener('keydown', keydown);
      return () => document.removeEventListener('keydown', keydown);
  }, []);

  return <div id='main'>
    <Welcome />
    <SidePanel />
    <Canvas />
    <SnapshotTray />
    <WhenCollaborationConnected>
      <CloseConnectionNotifier />
      <RemoteUsersCursors />
      <RemoteUsersTile />
      <SelectNameModal />
      <MyNicknameDisplay />
      <RemoteSynchronizer />
      <MismatchHashResync />
    </WhenCollaborationConnected>
    
    <ShapesRecovery />
    <NotificationDrawer />
  </div>;
}

export default App;
