import { useEffect } from "react";
import { useRemoteShapes } from "../../../store/hooks";
import { dispatch as remoteDispatch } from "../../../store/remote";
import { shapesHasher } from "../../../utils/shapes-hasher";

const { entries } = Object;

export function MismatchHashResync() {
    const remoteShapes = useRemoteShapes();
    useEffect(() => {
        entries(remoteShapes)
            .filter(([userId, [hash, shapes]]) => hash !== shapesHasher(shapes))
            .forEach(([userId]) => {
                remoteDispatch(':message/->send/request-sync', userId)
            })
    }, [remoteShapes]);

    return null;
}