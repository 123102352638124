export const tools = ['free-hand', 'line', 'oval', 'poly', 'text'] as const
export type iTools = typeof tools[number];
export type Arrows = 'end' | 'start' | 'both' | 'none';
export const toolsWithArrowSupport: iTools[] = ['free-hand', 'line']

interface Tool {
    type: iTools;
}

export const defaultColors = ['#000000', '#f7412d', '#1194f6', '#ffc200', '#9d1bb2', '#6DCE80', '#CE6D80', '#CE806D', '#806DCE'] as const;

export type iDefaultColors = typeof defaultColors[number];

export type Point = [number, number];

export type BaseShape = {
    tool: Tool,
    id: string,
    created: number,
}

export interface ShapePoly extends BaseShape {
    tool: ToolPoly,
    sideCount?: number,
    points: [...Point] | [...Point, ...Point]
}

export interface ShapeOval extends BaseShape {
    tool: ToolOval,
    points: [...Point] | [...Point, ...Point]
}

export interface ShapeFree extends BaseShape {
    tool: ToolFreeHand,
    points: number[]
}
export const isShapeFree = (s: BaseShape): s is ShapeFree => s.tool.type === 'free-hand';


export interface ShapeLine extends BaseShape {
    tool: ToolLine,
    points: [...Point] | [...Point, ...Point]
}

export interface ShapeText extends BaseShape {
    tool: ToolText,
    text: string,
    points: [number, number]
}

export const isShapeLine = (s: BaseShape): s is ShapeLine => s.tool.type === 'line';
export const isShapePoly = (s: BaseShape): s is ShapePoly => s.tool.type === 'poly';
export const isShapeOval = (s: BaseShape): s is ShapeOval => s.tool.type === 'oval';
export const isShapeText = (s: BaseShape): s is ShapeText => s.tool.type === 'text';

export type Shape = ShapeFree | ShapeLine | ShapePoly | ShapeOval | ShapeText;

export interface ToolPoly extends Tool {
    type: 'poly',
    lineWidth: number,
    lineColor: string,
}

export interface ToolOval extends Tool {
    type: 'oval',
    lineWidth: number,
    lineColor: string,
}

export interface ToolFreeHand extends Tool {
    type: 'free-hand',
    arrowType?: Arrows,
    lineWidth: number,
    lineColor: string,
}

export interface ToolLine extends Tool {
    type: 'line',
    arrowType?: Arrows,
    dashed?: boolean,
    lineWidth: number,
    lineColor: string;
}

export interface ToolText extends Tool {
    type: 'text',
    text: Settings['textConfig'],
    lineColor: string,
}
export type AllTools = ToolFreeHand | ToolLine | ToolPoly | ToolOval | ToolText;

export type Settings = {
    activeTool: iTools,
    lineColor: string,
    arrowType: Arrows,
    lineWidth: number,
    textConfig: {
        fontSize: number,
        font: string,
        fontWeight: 'bold'|'normal'|'italic',
    }
}

export const menus = ['tool', 'lineColor', 'arrowType', 'lineWidth', 'text', 'misc', 'colab'] as const;
export type iMenu = typeof menus[number];

export function toolSupports(tool: iTools, setting: iMenu): boolean {
    const supportMap: Record<iMenu, iTools[]|true> = {
        tool: true,
        lineColor: ['free-hand', 'line', 'oval', 'poly', 'text'],
        arrowType: ['free-hand', 'line'],
        lineWidth: ['free-hand', 'line', 'oval', 'poly'],
        text: ['text'],
        misc: true,
        colab: true,
    }

    return supportMap[setting] === true || (supportMap[setting] as any)?.includes(tool);
}