import { Shape } from "../../tools";
import { dissocIn, assocIn, updateIn } from "clj-ports";
import { WhiteBoard } from "../store.types";

export function keepLiveShape(s: WhiteBoard): WhiteBoard {
    if (!s.shapes.live) return s;
    return dissocIn(
        updateIn(s, ['shapes', 'vis'], v => v.concat(s.shapes.live)),
        ['shapes', 'live']
    )
}

export function replaceLiveShape(state: WhiteBoard, [s]: [s: Shape]): WhiteBoard {
    return assocIn(state, ['shapes', 'live'], s)
}
export function discardActive(s: WhiteBoard): WhiteBoard {
    return dissocIn(s, ['shapes', 'live'])
}

export function replaceAllShapes(state: WhiteBoard, [vis]: [shapes: Shape[]]) {
    return assocIn(state, ['shapes', 'vis'], vis)
}
export function initiateShape(s: WhiteBoard, [shape]: [shape: Shape]): WhiteBoard {
    return assocIn(s, ['shapes', 'live'], shape)
}

/**
 * higher order function to help make a live point update handler. 
 * 
 * @param updater 
 * @returns 
 */
export function updateLiveShapePoints<T extends Shape = never>(updater: (evt: MouseEvent, p: T['points']) => T['points']) {
    return (s: WhiteBoard, [evt]: [evt: MouseEvent]) => {
        if (!s.shapes.live) return s;

        return updateIn(s, ['shapes', 'live', 'points'], (p => updater(evt, p)))
    };
}