import React, { useEffect, useRef, useState } from 'react';
import { ShapeText } from '../../tools';
import { fadeColor } from '../../utils/const';
import { when } from '../../utils/when';

export const Text = ({ shape, live, faded }: { shape: ShapeText, live: boolean, faded?: boolean }) => {
    const {
        points: [x, y],
        tool: { lineColor }
    } = shape;

    const fill = faded ? fadeColor : lineColor;

    const tRef = useRef<SVGTextElement>(null);
    const [boxDims, setBoxDims] = useState<DOMRect>();

    useEffect(() => {
        if (tRef.current) setBoxDims(tRef.current.getBBox());
    }, [tRef, shape.text]);

    return <>
        <text
            ref={tRef}
            x={x}
            y={y}
            style={{ font: 'italic 22px sans-serif', fill, userSelect: 'none' }}
        >{shape.text || <>&nbsp;</>}</text>
        {when(boxDims && live, () => <rect
            fill='transparent'
            stroke={fill}
            strokeWidth={1}
            strokeDasharray={2}
            x={x - 4}
            y={y - boxDims!.height + 4}
            width={boxDims!.width + 12}
            height={boxDims!.height}
        />)}
    </>
        
}