import { memo, useEffect, useRef } from 'react';
import { usePartitionedLiveShapes, useHiddenRemoteUsers, useHighlightedUser, useVisShapes, useRemoteShapes, useRemoteLiveShapes } from '../store/hooks';
import { dispatcher } from '../store/main';
import { BaseShape, isShapeFree, isShapeLine, isShapeOval, isShapePoly, isShapeText, Shape } from '../tools';
import './canvas.css';
import { Free } from './shapes/free';
import { Line } from './shapes/line';
import { when } from '../utils/when';
import { Poly } from './shapes/poly';
import { Oval } from './shapes/oval';
import { Text } from './shapes/text';
import { partitionShape } from '../utils/partition-shape';
import { WhenCollaborationConnected } from './collaboration/when-connected';
const { entries } = Object;

const makeShape = 
    (live: boolean, faded: boolean = false) => 
    (shape: BaseShape) => {
        if (isShapeFree(shape)) return <Free data-shape-type='Free' key={shape.id} live={live} shape={shape} faded={faded} />
        if (isShapeLine(shape)) return <Line data-shape-type='Line' key={shape.id} live={live} shape={shape} faded={faded} />
        if (isShapePoly(shape)) return <Poly data-shape-type='Poly' key={shape.id} live={live} shape={shape} faded={faded} />
        if (isShapeOval(shape)) return <Oval data-shape-type='Oval' key={shape.id} live={live} shape={shape} faded={faded} />
        if (isShapeText(shape)) return <Text data-shape-type='Text' key={shape.id} live={live} shape={shape} faded={faded} />
};

const StaticShapes = memo(({ shapes, faded, live }: { shapes: BaseShape[], faded?: boolean, live?: boolean }) => <>
    {shapes.map(makeShape(!!live, faded))}
</>, (p, n) => p.shapes.length === n.shapes.length && p.faded === n.faded);

const LocalShapes = () => {
    const shapes = useVisShapes();
    const [liveShape, parts] = usePartitionedLiveShapes();
    const highlightedUser = useHighlightedUser();

    return <>
        <StaticShapes shapes={shapes} faded={!!highlightedUser && highlightedUser !== 'me'}/>
        {when(liveShape, () => makeShape(true)(liveShape!))}
        {when(parts?.length, () => <StaticShapes shapes={parts!} live />)}
    </>
}

const RemoteShapes = () => {
    const remoteShapes = useRemoteShapes();
    const remoteLive = useRemoteLiveShapes();
    const hidden = useHiddenRemoteUsers();

    const isUserVisible = ([id]: [string, any]) => !hidden[id];
    const hasShapes = ([, shape]: [string, any]) => Array.isArray(shape) ? shape.length : !!shape;
    const highlightedUser = useHighlightedUser();

    const [tails, staticParts]: [tails: Shape[], staticParts: Shape[]] = entries(remoteLive)
        .filter(isUserVisible)
        .filter(hasShapes)
        .map(([user, shape]) => ({...shape!, id: `${user}-${shape!.id}`}))
        .map(partitionShape(500))
        .reduce(([tailParts, allStaticParts], [tailPart, staticParts]) => [
            tailParts.concat([tailPart]),
            allStaticParts.concat(staticParts),
        ], [[], []] as [tails: Shape[], staticParts: Shape[]]);

    return <>
        {entries(remoteShapes).filter(isUserVisible).filter(hasShapes).map(([user, [,shapes]]) => <StaticShapes
            key={user}
            shapes={shapes}
            faded={!!highlightedUser && highlightedUser !== user}
        />)}
        {when(staticParts.length, () => <StaticShapes shapes={staticParts} live />)}
        {when(tails.length, () => tails.map(makeShape(true)))}
        
    </>
}

const Svg = () => {
    return <svg id='user-canvas'
        preserveAspectRatio='xMidYMid meet'
    >
        <LocalShapes />
        <WhenCollaborationConnected>
            <RemoteShapes />
        </WhenCollaborationConnected>
    </svg>
}

export const Canvas = () => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!ref.current) return;
        const e = ref.current;

        const handlers: Partial<Record<keyof HTMLElementEventMap, EventListener>> = {
            'mousedown': dispatcher(':mouse/down'),
            'mouseup': dispatcher(':mouse/up'),
            'mousemove': dispatcher(':mouse/move'),
            'mouseleave': dispatcher(':mouse/up'),
            'contextmenu': e => e.preventDefault(),
        }

        entries(handlers).forEach(p => e.addEventListener(...p));

        return () => entries(handlers).forEach(p => e.removeEventListener(...p));
    }, [ref]);

    return <div ref={ref} id='canvas-container'>
        <Svg />
    </div>
}