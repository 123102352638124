let cache: Record<string, any> = {};
let sender: Record<string, any> = {};
export function debounce(k: string, action: () => any, ms: number) {
    sender[k] = action;
    if (k in cache) return;
    cache[k] = setTimeout(async () => {
        const sendFn = sender[k];
        delete cache[k];
        sendFn();
    }, ms)
}