import { useEffect, useState } from "react";
import { randId } from "../utils/randId";
import * as ls from '../utils/localstore';

const connections: Record<string, any> = {}
export type iRawCon = {
    sendMessage: (m: any) => any,
    sub: (fn: (m: any) => any) => () => any,
    makeMessage: (type: string, m: any, userId?: string) => any,
}

export const rawConnection = (sessId: string): iRawCon => connections[sessId] = (connections[sessId] ?? (() => {
    const userId = ls.get(':user/id') || randId(10);
    ls.set(':user/id', userId);

    console.log({userId})
    console.log('rtcm:///', 'making connection', sessId);
    const con = new RTCMultiConnection();

    con.socketURL = 'https://rtcmulticonnection.herokuapp.com:443/';

    (window as any).con = con;
    con.session = { data: true };
    con.enableLogs = false;

    let initialized = false;

    const messageQueue: any[] = [];
    con.onopen = (e: any) => {
        console.log('rtcm:///', 'init')
        initialized = true;
        messageQueue.forEach(con.send);
    };

    con.userid = userId;

    const makeMessage = (type: string, payload: any) => ({
        type,
        ts: Date.now(),
        payload,
        userId: userId,
        test: 'in'
    })
    const sendMessage = (m: any, toUserId?: string) => {
        if (initialized) return con.send(m, toUserId)
        else messageQueue.push(m)
    }

    con.join({
        sessionid: `whiteboard:/${sessId}`,
        session: con.session,
    }, (x: boolean) => {
        if (!x) {
            console.log('rtcm:///', 'failed to join, openning new');
            con.open(`whiteboard:/${sessId}`, () => {
                window.addEventListener('unload', con.close);
            })
        } else {
            window.addEventListener('unload', con.close);
        }
    })
    let subs: any[] = [];
    con.onmessage = (e: any) => {
        // console.log('rtcm:///', 'received message', e);
        subs.forEach(s => {
            try {
                s({ ...e.data})
            } catch {}
        })
    };

    return {
        makeMessage,
        sendMessage,
        sub: (fn: any) => {
            subs.push(fn);
            return () => subs = subs.filter(s => s !== fn);
        }
    };
})());

export const useRawConnection = <T, T2>(sessId: string): [T|'init',T2] => {
    const [lastMessage, setLastMessage] = useState<T|'init'>('init');
    const { sendMessage, sub } = rawConnection(sessId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => sub(setLastMessage), []);
    return [lastMessage, sendMessage] as any;
}