import { ArrowsMenu } from './arrows-menu';
import { CollaborationMenu } from './collaboration-menu';
import { ColorsMenu } from './colors-menu';
import { LineWidth } from './line-width';
import { SettingsMenu } from './settings-menu';
import { ToolsMenu } from './tools-menu';

export const SidePanel = () => <div className='left-panel'>
    <ToolsMenu />
    <LineWidth />
    <ColorsMenu />
    <ArrowsMenu />
    <SettingsMenu />
    <CollaborationMenu />
</div>
