const debounceCache: Record<string, any> = {};

const keys = [':whiteboard/shapes', ':user/nickname', ':user/id'] as const;
type iKeys = typeof keys[number];

export const set = <T extends iKeys>(key: T, value: any, debounceMs: number = 0) => {
    const storeKey = typeof key === 'string' ? key : JSON.stringify(key);
    const storeValue = JSON.stringify(value);

    if (!debounceMs) {
        window.localStorage.setItem(storeKey, storeValue);
    } else {
        clearTimeout(debounceCache[storeKey]);
        debounceCache[storeKey] = setTimeout(() => {
            window.localStorage.setItem(storeKey, storeValue);
        }, debounceMs)
    }
}

export const get = <T extends iKeys>(key: T) => {
    const storeKey = typeof key === 'string' ? key : JSON.stringify(key);

    try {
        return JSON.parse(window.localStorage.getItem(storeKey)!)
    } catch {}
}