import { useEffect, useState } from "react"
import { dispatch } from "../store/main";
import * as ls from "../utils/localstore";
import { NotifyModal } from "./notification-drawer";

export const ShapesRecovery = () => {
    const [showRecovered, setShowRecovered] = useState(false);
    const [backedupShapes] = useState(ls.get(':whiteboard/shapes'));

    useEffect(() => {
        if (!Array.isArray(backedupShapes) || !backedupShapes?.length) return;
        dispatch(':shape/replace-all', backedupShapes);
        setShowRecovered(true);
    }, [backedupShapes])

    if (!showRecovered) return null;

   return <NotifyModal
        deps={[]}
        primary={['Ok', () => setShowRecovered(false)]}
        secondary={['Discard', () => {
            dispatch(':shape/replace-all', []);
            setShowRecovered(false)
        }]}
        autoClose={[6000, () => setShowRecovered(false)]}
    >
       <div className='text-center'>Loaded drawing from previous work.</div>
   </NotifyModal>
}