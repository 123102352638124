import { useState } from 'react';
import { multiDispatcher } from '../../store/main';
import { defaultColors } from '../../tools';
import { when } from '../../utils/when';
import { ActionMenu } from './action-menu'
import { SketchPicker } from 'react-color';
import cn from 'classnames';
import { useSettings } from '../../store/hooks';


const ColorButton = ({ color }: { color: string }) => <button
    className='color'
    style={{color, borderColor: color, backgroundColor: color }}
    onClick={multiDispatcher([
        [':config/tools/change-color', color],
        [':action-menu/hide']
    ])}
/>

const Default = () => <div className='color-buttons'>
    {defaultColors.map(c => <ColorButton color={c} key={c} />)}
</div>

const Custom = ({ color }: {color: string }) => <SketchPicker
    color={color}
    onChangeComplete={({hex}) => multiDispatcher([
        [':config/tools/change-color', hex],
        [':action-menu/hide']
    ])()}
/>

export const ColorsMenu = () => {
    const settings = useSettings();
    const [type, setType] = useState<'default'|'custom'>('default');
    const [setDefault, setCustom] = [() => setType('default'), () => setType('custom')]

    return <ActionMenu menu='lineColor' ico='menu/choose-color' tag='colors' style={{color: settings.lineColor}}>
        {when(type === 'default', <Default />)}
        
        {when(type === 'custom', <div className="pb-4"><Custom color={settings.lineColor} /></div>)}
        
        <div className='flex justify-evently'>
            <button onClick={setDefault} className={cn('btn', 'flex-grow', { active: type === 'default'})}>Default</button>
            <button onClick={setCustom} className={cn('btn', 'flex-grow', { active: type === 'custom'})}>Custom</button>
        </div>
    </ActionMenu>
}