import { ReactNode, useEffect, useState } from 'react';
import { useNotificationDrawer } from '../store/hooks';
import { dispatch } from '../store/main';
import { when } from '../utils/when';

/**
 * This element is in the top level app and comnponent that want to use it 
 * should wrap their modal with NotifyModal 
 */
//
export const NotificationDrawer = () => {
    const notification = useNotificationDrawer();
    const hasNotification = !!notification;
    const [initialized, setInitialized] = useState(false); // used to trigger transition effects


    const { body, config: { primary, secondary, autoClose } } = notification || { config: {} } as any;

    // initialize effect
    useEffect(() => {
        // async so dom can get initial value
        when(hasNotification, () => (async () => setInitialized(true))());
    }, [hasNotification]);

    // auto select after ms effect
    useEffect(() => {
        if (!autoClose) return;
        const timeout = setTimeout(autoClose![1], autoClose![0]);
        return () => clearTimeout(timeout);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!!autoClose]);

    // todo: change this to allow for slide up animation
    if (!notification) return null;

    const w = 'w-96';

    return <>
        <div className='fixed inset-0 bg-black z-10 bg-opacity-40' />
        <div className='absolute top-0 w-full flex justify-center z-20'>
            <div className={`absolute bg-white border-grey-30  pt-1 shadow-lg border ${w} border-t-0 rounded-b`}>
                <div className='relative p-2 flex flex-col'>
                    {body}
                    <div className='mt-4 flex justify-evenly text-center'>
                        {when(primary, () => <button disabled={primary![2]} className='btn flex-grow mr-2' onClick={primary![1]}>{primary![0]}</button>)}
                        {when(secondary, () => <button disabled={secondary![2]} className='btn flex-grow ml-2' onClick={secondary![1]}>{secondary![0]}</button>)}
                    </div>
                </div>
                {when(!!autoClose, () => <div id='timer' style={{transitionDuration: `${autoClose![0]}ms`, width: initialized ? 0 : undefined}} className={`absolute left-0 ${w} transition-all ease-linear top-0 h-1 bg-highlight`}/>)}
            </div>
        </div>
    </>
}

type btn = [btnText: string, handler: (...any: any[]) => any, disabled?: boolean]
export type iNotificationConfig = {primary?: btn, secondary?: btn, autoClose?: [ms: number, fn?: (...any: any[]) => any]}
export const NotifyModal = (props: { children: ReactNode } & iNotificationConfig & { deps: any[] }) => {

    const {primary, secondary, autoClose} = props;

    useEffect(() => () => dispatch(':notification/hide'), []);

    useEffect(() => {
        dispatch(':notification/show', props.children, { primary, secondary, autoClose })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, props.deps)
    return null;
}