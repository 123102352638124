import { assoc, update } from "clj-ports";
import { WhiteBoard } from "../store.types";


export function clearCanvas(s: WhiteBoard) {
    return {
        ...s,
        shapes: {
            vis: [],
            undid: [],
        }
    }
}
export function undo(state: WhiteBoard): WhiteBoard {
    return update(state, 'shapes', ({vis, undid, ...rest}) => ({
        ...rest,
        vis: vis.slice(0, -1),
        undid: undid.concat(vis.slice(-1))
    }))
}

export function redo(state: WhiteBoard): WhiteBoard {
    return update(state, 'shapes', ({vis, undid, ...rest}) => ({
        ...rest,
        vis: vis.concat(undid.slice(-1)),
        undid: undid.slice(0, -1)
    }))
}

export function storeSnapshot(state: WhiteBoard, [full, thumb]: [full: string, thumb: string]): WhiteBoard {
    if (state.snapshots.slice(-1)[0]?.shapes === state.shapes.vis) return state;

    const nextSnap = [{
        full,
        thumb,
        time: Date.now(),
        shapes: state.shapes.vis,
    }];

    return update(state, 'snapshots', snaps => snaps.slice(-1)[0]?.shapes === state.shapes.vis
        ? snaps
        : snaps.concat(nextSnap)
    );
}

export function tapSnap(store: WhiteBoard): WhiteBoard {
    // need something that won't pass strict equality check
    return assoc(store, 'snapshotTap', {})
}