import '../index.d';
import { 
    
} from '@fortawesome/free-solid-svg-icons'

import {
    faPalette, faPencilAlt, faExchangeAlt, faEllipsisH, faLink, faTriangle, faChalkboard, faEyeSlash,
    faCamera, faUndo, faArrowRight, faRedo, faGripLines, faArrowLeft, faFile, faDrawCircle, faParagraph, faArrowsAltH,
    faEye, faTrashAlt, faObjectUngroup, faMousePointer, faCameraRetro, faWindowMinimize
} from '@fortawesome/pro-duotone-svg-icons';
import {

} from '@fortawesome/free-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { CSSProperties } from 'react';
import { identity } from 'clj-ports';
import { emptyObj } from '../utils/const';

const projectMap = {
    'menu/choose-color': faPalette,
    'menu/tool/free-hand': faPencilAlt,
    'menu/tool/line': faGripLines,
    'menu/tool/text': faParagraph,
    'menu/tool/poly': faObjectUngroup,  
    'menu/tool/oval': faDrawCircle,
    'menu/tool/line-width': faTriangle,
    'menu/tool/arrow/end': faArrowRight,
    'menu/tool/arrow/start': faArrowLeft,
    'menu/tool/arrow/both': faArrowsAltH,
    'menu/tool/arrow/none': faExchangeAlt,
    'menu/misc': faEllipsisH,
    'menu/session': faLink,
    'session/user/vis': faEye,
    'session/user/hidden': faEyeSlash,
    'session/user/cursor': faMousePointer,
    'misc/undo': faUndo,
    'misc/redo': faRedo,
    'misc/clear': faFile,
    'misc/delete': faTrashAlt,
    'misc/snapshot': faCamera,
    'misc/preview': faChalkboard,
    'snapshots/collapse-tray': faWindowMinimize,
    'snapshots/animated-camera': faCameraRetro,
} as const;

export type iProjectIcons = keyof typeof projectMap;
type iProps = {
    ico: iProjectIcons,
    onClick?: (...any: any[]) => any,
    className?: string,
    style?: CSSProperties,
}
export function Icon({ico, onClick = identity, className = '', style = emptyObj}: iProps) {
    return <FontAwesomeIcon style={style} icon={projectMap[ico]} className={className} onClick={onClick} />
}
