import React from 'react';
import { ShapeLine } from '../../tools';
import { fadeColor } from '../../utils/const';
import { when } from '../../utils/when';
import { Marker } from './marker';

export const Line = ({ shape, live, faded }: { shape: ShapeLine, live: boolean, faded?: boolean }) => {
    const {
        points: [x1, y1, x2, y2],
        tool: { lineColor, lineWidth }
    } = shape;

    if (!x2) return null;

    const markerStart = ['start', 'both'].includes(shape.tool.arrowType || '');
    const markerEnd = ['end', 'both'].includes(shape.tool.arrowType || '');

    const color = faded ? fadeColor : lineColor;

    return <>
        {when(!!shape.tool.arrowType, <Marker color={lineColor} id={`marker-${shape.id}`} />)}
        <line 
            x1={x1} y1={y1} x2={x2} y2={y2}
            markerStart={when(markerStart, `url(#marker-${shape.id})`)}
            markerEnd={when(markerEnd, `url(#marker-${shape.id})`)}
            style={{
                strokeWidth: lineWidth,
                stroke: live ? color : color,
                // strokeDasharray: live ? lineWidth*2 : 0,
            }}
        />
    </>
}