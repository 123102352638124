export const Marker = ({ color, id }: { color: string, id: string }) => {
    return <marker
        id={id}
        viewBox='0 0 10 10'
        refX={5}
        refY={5}
        markerWidth={7}
        markerHeight={7}
        orient='auto-start-reverse'
    >
        <path d='M 0 0 L 10 5 L 0 10 z' fill={color} />
    </marker>
}