import { useRemoteUserHidden, useRemoteUserIds, useRemoteUserName } from '../store/hooks';
import './remote-users-tile.css';
import { dispatch } from '../store/main';
import { Avatar } from './avatar';
import { Icon } from './icon';

function UserRow({ userId }: { userId: string }) {
    const toggleVisible = () => dispatch(':user/remote/toggle-user-visibility', userId);
    const highlighUser = () => dispatch(':user/highlight', userId)
    const unhighlightUser = () => dispatch(':user/highlight');

    const nickname =  useRemoteUserName(userId);
    const hidden = useRemoteUserHidden(userId);

    return <div key={userId} className='user-row' onClick={toggleVisible}>
        <button><Icon className='mr-2' ico={hidden ? 'session/user/hidden' : 'session/user/vis'} /></button>
        <div className="flex-grow" />
        <div className="flex items-center" onMouseOver={highlighUser} onMouseOut={unhighlightUser}>
            <span >{nickname}</span>
            <Avatar size={34} nickname={nickname} className='pl-2' />
        </div>
    </div>
}
export function RemoteUsersTile() {
    const userIds = useRemoteUserIds();

    return <div className='remote-users-tile'>
        <div className='title'>Participants</div>
        <div className='body'>
            {userIds.map(id => <UserRow  key={id} userId={id} />)}
        </div>
    </div>
}