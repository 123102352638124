import { useEffect, useState } from 'react'
import { dispatch } from '../store/remote';

const urlToSessionId = (url: string) => url.includes('/session/') ? url.split('/session/').pop() : undefined;
const sessionToUrl = (sessionId: string) => `/session/${sessionId}`;

export function useCollaborationSession(): [string|undefined, (id: string) => any] {
    const [sessionId, setSessionId] = useState<string|undefined>(urlToSessionId(window.location.pathname));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (sessionId && !window.location.href.includes(sessionId))
            window.history.replaceState(null, '', sessionToUrl(sessionId));
        if (!sessionId && urlToSessionId(window.location.pathname))
            setSessionId(urlToSessionId(window.location.pathname))
    });
    useEffect(() => {
        if (sessionId) dispatch(':session/connect', sessionId)
    }, [sessionId])

    return [sessionId, setSessionId]
}
