import { useCollaborationSession } from '../hooks/use-collaboration-session';
import { Arrows, Shape, toolSupports, iMenu } from '../tools';
import { partitionShape } from '../utils/partition-shape';
import { useSelector as useMainSelector } from './main';
import { useSelector as useRemoteSelector } from './remote';
const { values } = Object;

function strArsMatch(l: string[], r: string[]) {
    return l.join('::') === r.join('::')
}

export const useShapes = () => useMainSelector(s => s.shapes);
export const useVisShapes = () => useMainSelector(s => s.shapes.vis);
export const useLiveShape = () => useMainSelector(s => s.shapes.live);
export const usePartitionedLiveShapes = (): []|[ live: Shape, parts: Shape[] ] => {
    const live = useLiveShape();
    if (!live) return [];

    return partitionShape(500, live);
}

export const useSettings = () => useMainSelector(s => s.settings);
export const useToolSupportsMenu = (menu: iMenu) => {
    const { activeTool } = useSettings();
    return toolSupports(activeTool, menu);
}

export const useToolArrowType = (): Arrows|undefined => {
    const tool = useSettings() as any;
    return tool.arrowType;
}
export const useVisMenu = () => useMainSelector(s => s.visibleActionMenu);


export const useWelcomeVisable = () => {
    const { vis, live } = useShapes();
    const [sessionId] = useCollaborationSession();

    return !vis.length && !live && !sessionId;
};

export const useHiddenRemoteUsers = () => useMainSelector(s => s.hiddenRemoteUsers);

export const useNickname = () => useMainSelector(s => s.nickname);
export const useRename = () => useMainSelector(s => !!s.rename);
export const useHighlightedUser = () => useMainSelector(s => s.highlightedUser);

export const useNotificationDrawer = () => useMainSelector(s => s.notificationDrawer);

export const useLastPos = () => useMainSelector(s => s.lastXY);

export const useSnapshots = () => useMainSelector(s => s.snapshots);
export const useSnapTap = () => useMainSelector(s => s.snapshotTap);

export const useColabConnected = () => useRemoteSelector(s => !!s.connection);
export const useRemoteShapes = () => useRemoteSelector(s => s.shapes);
export const useRemoteLiveShapes = () => useRemoteSelector(s => s.liveShapes);

export const useRemoteUser = (userId: string) => useRemoteSelector(
    s => s.users[userId]
)

export const useRemoteUserIds = () => useRemoteSelector(
    s => Object.keys(s.users),
    strArsMatch
);

export const useRemoteUserShapes = (userId: string) => useRemoteSelector(
    ({shapes: { [userId]: s } }) => s,
    ([h1], [h2]) => h1 !== h2
);

export const useRemoteLiveUserShape = (userId: string) => useRemoteSelector(
    ({liveShapes: { [userId]: s } }) => s,
    (l, r) => l?.points !== r?.points
)

export const useRemoteUserName = (userId: string) => useRemoteSelector(
    ({users: { [userId]: u } }) => u.nickname,
)

export const useRemoteUserHidden = (userId: string) => useRemoteSelector(
    ({ hiddenRemoteUsers: { [userId]: h }}) => !!h
)

export function useResyncRequested() {
    return useRemoteSelector(({ requestingSync }) => requestingSync)
}