import { Shape } from "../tools";

export const keys: {[k: string]: (e: KeyboardEvent, liveShape?: Shape) => boolean} = {
    isTyping:   (_, liveShape) => liveShape?.tool?.type === 'text',
    isEsc:      e => e.key === 'Escape',
    isNewSlate: e => e.metaKey && e.key === 'k',
    isRedo:     e => e.metaKey && e.key === 'z' && e.shiftKey,
    isUndo:     e => e.metaKey && e.key === 'z' && !e.shiftKey,
    isSnap:     e => e.metaKey && e.key === 'p',
} as const;
