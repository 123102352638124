import { useCollaborationSession } from '../../hooks/use-collaboration-session';
import { randId } from '../../utils/randId';
import { when } from '../../utils/when';
import { ActionMenu } from './action-menu';


export const CollaborationMenu = () => {
    const [sessionId, setSessionId] = useCollaborationSession();
    return <>
        <ActionMenu menu='colab' ico='menu/session' tag='share-link' title={when(sessionId, 'Collaboration link')}>
            <div className='share'>
                {!sessionId 
                    ? <button className='' onClick={() => setSessionId(randId(10))}>Start Session</button>
                    : <a className="whitespace-nowrap" href={`/session/${sessionId}`}>{`${window.location.origin}/session/${sessionId}`}</a>}
            </div>
        </ActionMenu>
    </>;
}