import { iProjectIcons } from '../components/icon';
import { iTools } from '../tools'

export const fas: Record<iTools, iProjectIcons> = {
    'free-hand': 'menu/tool/free-hand',
    line: 'menu/tool/line',
    text: 'menu/tool/text',
    poly: 'menu/tool/poly',
    oval: 'menu/tool/oval',
};

