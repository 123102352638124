import { useLastPos } from "../../../store/hooks";
import { debounce } from "../../../utils/debounce";
import { dispatcher as remoteDispatcher } from "../../../store/remote";
import { useEffect } from "react";

export function PositionSender() {
    const pos = useLastPos();
    useEffect(() => {
        if (pos) debounce('user-pos', remoteDispatcher(':message/->send/last-xy', ...pos), 20);
    }, [pos])
    return null;
}

