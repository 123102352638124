import { createGlobalStore } from 'react-redux-simple-store';
import { WhiteBoard } from './store.types';
import * as ls from '../utils/localstore';
import { store as mainStore } from './main';
import { store as remoteStore } from './remote';

export const store = createGlobalStore(
    [mainStore, remoteStore],
    [],
    process.env.NODE_ENV === 'development'
);

let lastShapes: any;
store.subscribe(() => {
    const state = store.getState() as { board: WhiteBoard };

    const key = state?.board?.shapes?.vis?.map?.(({id}) => id).join(',');
    if (state?.board?.shapes?.vis && lastShapes !== key) {
        lastShapes = key;
        ls.set(':whiteboard/shapes', state.board.shapes.vis, 200);
    }
});