import { useState, useEffect } from "react";
import { useVisShapes, useLiveShape, useResyncRequested } from "../../../store/hooks";
import { dispatch as remoteDispatch, dispatcher as remoteDispatcher } from "../../../store/remote";
import { Shape } from "../../../tools";
import { debounce } from "../../../utils/debounce";
import { interval } from "../../../utils/interval";
import { shapesHasher } from "../../../utils/shapes-hasher";

export function FinishedShapesSender() {
    // what we send
    const myShapes = useVisShapes();
    const requestSyncUserId = useResyncRequested();
    const [lastShapes, setLastShape] = useState<Shape[]>([]);
    const hash = shapesHasher(myShapes);

    // keep a ping to ensure remote users are synced - especially first join
    useEffect(
        interval(remoteDispatcher(':message/->send/ping-shapes-hash', hash), 1500, true),
        [hash]
    );

    useEffect(() => {
        if (!requestSyncUserId) return;
        remoteDispatch(':message/->send/all-shapes', myShapes, requestSyncUserId)
    }, [requestSyncUserId])

    useEffect(() => {
        if (!myShapes) return;
        const hash = shapesHasher(myShapes);

        if (!myShapes.length) {
            remoteDispatch(':message/->send/all-shapes', [])
        } if (lastShapes.length < myShapes.length) {
            remoteDispatch(':message/->send/new-shape', hash, myShapes.slice(-1).pop()!)
            // send('new-shape', [hash, myShapes.slice(-1).pop()!])
        } else if (lastShapes.length > myShapes.length) {
            const removeId = lastShapes.findIndex(s => !myShapes.includes(s));
            remoteDispatch(':message/->send/remove-shape', hash, removeId);
        }

        setLastShape(myShapes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myShapes]);
    return null;
}

export function LiveShapeSender() {
    const liveShape = useLiveShape();
    useEffect(() => {
        debounce('send-live', remoteDispatcher(':message/->send/live-shape', liveShape), 10);
     }, [liveShape]);
     return null;
}