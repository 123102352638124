import { Store } from 'react-redux-simple-store';
import { keys } from './keyboard';
import { Settings, ShapeFree } from '../tools';
import { WhiteBoard } from './store.types';
import { dissoc, cond, assoc } from 'clj-ports';
import * as ls from '../utils/localstore';
import { iNotificationConfig } from '../components/notification-drawer';
import { ReactNode } from 'react';
import { eventXy } from '../utils/event-xy';
import { textKeyDownHandler } from './events/text-keydown';
import * as shapeHanndlers from './events/shape-event-handlers';
import * as configHandlers from './events/config-events-handler';
import * as actionHandlers from './events/actions-events.handlers';
import * as userHandlers from './events/user-events-handlers';
import { debounce } from '../utils/debounce';
import { randId } from '../utils/randId';
import { midPoint2d, spreadPoints } from '../utils/points';
import { svgAsPngUri } from 'save-svg-as-png';


const initState: WhiteBoard = {
    nickname: ls.get(':user/nickname') || undefined,
    settings: {
        activeTool: 'free-hand',
        lineWidth: 2,
        lineColor: '#000',
        arrowType: 'none',
        textConfig: {
            fontSize: 12,
            font: 'sans-serif',
            fontWeight: 'normal'
        }
    },
    snapshots: [],
    shapes: {
        vis: [],
        undid: [],
    },
    t: '',
    hiddenRemoteUsers: {}
}

const isMouseEvent = (evt: Event): evt is MouseEvent => 'button' in (evt as any);

const mouse = (type: 'down'|'up'|'move') => (state: WhiteBoard, [evt]: [evt?: Event]) => {
    if (!evt || !isMouseEvent(evt)) return;

    debounce('dispatch-last-xy', () => dispatch(':user/set-last-xy', ...eventXy(evt)), 10);
    if (type === 'down' && evt.button !== 0) return;

    if (type === 'down' && state.visibleActionMenu) {
        s.queue(':action-menu/hide')
        return;
    }
    if (type === 'down' || state.shapes.live) {
        s.queue(`:mouse/${state.settings.activeTool}/${type}`, evt);
    }
}

const initGenericShape = (evt: MouseEvent, settings: Settings) => ({
    id: randId(10),
    points: eventXy(evt),
    created: Date.now(),
    tool: {
        type: settings.activeTool as any,
        lineWidth: settings.lineWidth,
        lineColor: settings.lineColor,
        arrowType: settings.arrowType,
    }
})

const s = new Store('board', initState, {
    ':mouse/move': mouse('move'),
    ':mouse/up': mouse('up'),
    ':mouse/down': mouse('down'),

    // Free hand shape mouse events
    // these stay here for type safety since we are using s.queue
    ':mouse/free-hand/down': (state, [evt]: [evt: MouseEvent]) => {
        s.queue(':shape/initiate-shape', initGenericShape(evt, state.settings))
    },
    ':mouse/free-hand/move': shapeHanndlers.updateLiveShapePoints<ShapeFree>((evt, points) => {
        const point = eventXy(evt);
        const lastPoint = points.slice(-2) as [number, number];
        const midxy = midPoint2d([lastPoint!, point]);
        return spreadPoints(points.concat(midxy as any), 2)
    }),
    ':mouse/free-hand/up': (state, [evt]: [evt: MouseEvent]) => {
        s.queue(':shape/keep-live');
    },

    ':mouse/line/down': (state, [evt]: [evt: MouseEvent]) => {
        s.queue(':shape/initiate-shape', initGenericShape(evt, state.settings))
    },
    ':mouse/line/move': shapeHanndlers.updateLiveShapePoints<ShapeFree>((evt, points) => {
        return [points[0], points[1], ...eventXy(evt)]
    }),
    ':mouse/line/up': (state, [evt]: [evt: MouseEvent]) => {
        s.queue(':shape/keep-live');
    },
    ':mouse/oval/down': (state, [evt]: [evt: MouseEvent]) => {
        s.queue(':shape/initiate-shape', initGenericShape(evt, state.settings))
    },
    ':mouse/oval/up': (state, [evt]: [evt: MouseEvent]) => {
        s.queue(':shape/keep-live');
    },
    ':mouse/oval/move': shapeHanndlers.updateLiveShapePoints<ShapeFree>((evt, points) => {
        return [points[0], points[1], ...eventXy(evt)]
    }),
    
    ':mouse/poly/down': (state, [evt]: [evt: MouseEvent]) => {
        s.queue(':shape/initiate-shape', initGenericShape(evt, state.settings))
    },
    ':mouse/poly/up': (state, [evt]: [evt: MouseEvent]) => {
        s.queue(':shape/keep-live');
    },
    ':mouse/poly/move': shapeHanndlers.updateLiveShapePoints<ShapeFree>((evt, points) => {
        return [points[0], points[1], ...eventXy(evt)]
    }),
    
    ':mouse/text/down': (state, [evt]: [evt: MouseEvent]) => {
        s.queue(':shape/keep-live');
        s.queue(':shape/initiate-shape', {
            id: randId(10),
            points: eventXy(evt),
            text: '',
            created: Date.now(),
            tool: {
                type: 'text',
                lineColor: state.settings.lineColor,
                text: state.settings.textConfig
            }
        })
    },
    ':mouse/text/move': (state, [evt]: [evt: MouseEvent]) => state,
    ':mouse/text/up': (state, [evt]: [evt: MouseEvent]) => state,

    ':shape/initiate-shape': shapeHanndlers.initiateShape,
    ':shape/keep-live': shapeHanndlers.keepLiveShape,
    ':shape/replace-live': shapeHanndlers.replaceLiveShape,
    ':shape/discard-active': shapeHanndlers.discardActive,
    ':shape/replace-all': shapeHanndlers.replaceAllShapes,

    ':config/tools/change-type': configHandlers.changeType,
    ':config/tools/change-color': configHandlers.changeColor,
    ':config/tools/change-line-width': configHandlers.changeLineWidth,
    ':config/tools/change-arrow': configHandlers.changeArrowType,

    ':action-menu/hide': dissoc('visibleActionMenu'),
    ':action-menu/set-visible':
    (state, [menu]: [menu: string]) => state.visibleActionMenu === menu
        ? dissoc(state, 'visibleActionMenu')
        : assoc(state, 'visibleActionMenu', menu),


    ':keyboard/keydown':
    ({shapes: { live }}, [evt]: [evt: KeyboardEvent]) => cond(
        keys.isEsc(evt),          () => {evt.preventDefault(); s.queue(':actions/esc'); },
        keys.isNewSlate(evt),     () => {evt.preventDefault(); s.queue(':actions/clear-canvas'); },
        keys.isRedo(evt),         () => {evt.preventDefault(); s.queue(':actions/redo'); },
        keys.isUndo(evt),         () => {evt.preventDefault(); live?.tool.type === 'text' 
                                            ? s.queue(':shape/discard-active')
                                            : s.queue(':actions/undo'); },
        keys.isTyping(evt, live), () => {evt.preventDefault(); s.queue(':tool/text/keydown', evt) },
        keys.isSnap(evt),         () => {evt.preventDefault(); s.queue(':actions/->initiate-snapshot') },
    ),

    ':tool/text/keydown': textKeyDownHandler,
    ':user/set-last-xy': userHandlers.setUserLastXy,
    ':user/set-nickname': userHandlers.setNickname,
    ':user/rename': userHandlers.renameUser,
    ':user/highlight': userHandlers.userHighlight,
    ':user/remote/toggle-user-visibility': userHandlers.toggleRemoteUserVis,

    ':actions/esc': (state) => {
        s.queue(':action-menu/hide');
        s.queue(':shape/discard-active');
    },
    ':actions/clear-canvas': actionHandlers.clearCanvas,
    ':actions/undo': actionHandlers.undo,
    ':actions/redo': actionHandlers.redo, 
    ':actions/tap-snapshot': actionHandlers.tapSnap,
    ':actions/->initiate-snapshot': { thunk: async function initiateSnap() {
        // png is smaller than svg and we already have the data points
        const full = await svgAsPngUri(document.getElementById('user-canvas'), { encoderOptions: 1 } );
        const thumb = await svgAsPngUri(document.getElementById('user-canvas'), { encoderOptions: .5, scale: .4 } );
        s.dispatch(':actions/tap-snapshot')
        s.dispatch(':actions/store-snapshot', full, thumb);
    }},
    ':actions/store-snapshot': actionHandlers.storeSnapshot,

    // there is a `NotifyModal` component wrapper you should use instead of these directly
    ':notification/hide': dissoc('notificationDrawer'),
    ':notification/show': (state, [body, config]: [body: ReactNode, conf: iNotificationConfig]) => assoc(state, 'notificationDrawer', { body, config }),    
});

export const dispatch = s.dispatch;
export const dispatcher = s.dispatcher;
export const multiDispatcher = s.multiDispatch;
export const useSelector = s.useSelector;
export const store = s;