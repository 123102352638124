import { assoc, assocIn, updateIn } from "clj-ports";
import { Shape } from "../../tools";
import { shapesHasher } from "../../utils/shapes-hasher";
import { baseMessage, iRemoteStore, iRemoteUser } from "../remote";

function shouldMeterNearlyDuplicateUpdate(cur?: Shape, next?: Shape) {
    if (!cur) return false;
    if (!next) return false;
    if (next.tool.type !== 'free-hand') return false;
    return Math.abs(next.points.length - cur.points.length) <= 5;
}

export function liveShapeHandler(state: iRemoteStore, [m]: [m: baseMessage<'live-shape'>]) {
    const { payload: [ newShape ], userId } = m;
    if (shouldMeterNearlyDuplicateUpdate(state.liveShapes[userId], newShape)) return;
    return assocIn(state, <any>['liveShapes', userId], <any>newShape);
}
export function newShapeHandler(state: iRemoteStore, [m]: [m: baseMessage<'new-shape'>]) {
    const { userId, payload: [newHash, newShape] } = m;

    return updateIn(state, ['shapes', userId], (shapes: [string, Shape[]]) => [
        newHash,
        shapes?.[1].some(s => s.id === newShape.id)
            ? shapes![1]
            : (shapes?.[1] || []).concat([newShape])
    ]);
}
export function removeShapeHandler(state: iRemoteStore, [{payload, userId}]: [m: baseMessage<'remove-shape'>]) {
    const [newHash, idx] = payload;
    return updateIn(state, ['shapes', userId], ([_, list]) => [
        newHash,
        list.slice(0, idx).concat(list.slice(idx + 1))
    ]);
}
export function pingShapesHashHandler(state: iRemoteStore, [{userId, payload: [newHash]}]: [m: baseMessage<'ping-shapes-hash'>]) {
    return updateIn(state, ['shapes', userId], ([h, s] = ['', []]) => [
        newHash,
        s
    ])
}

export function allShapesHandler(state: iRemoteStore, [{userId, payload: [s]}]: [m: baseMessage<'all-shapes'>]) {
    return assocIn(state, <any>['shapes', userId], <any>[shapesHasher(s), s]);
}

export function whoHandler(state: iRemoteStore, [{userId, payload: [nickname]}]: [m: baseMessage<'who'>]) {
    return updateIn(state, ['users', userId], (u: iRemoteUser) => ({
        ...u,
        nickname
    }))
}
export function requestSyncHandler(state: iRemoteStore, [{userId}]: [m: baseMessage<'request-sync'>]) {
    return assoc(state, 'requestingSync', userId);
}
export function disconnectHandler(state: iRemoteStore, [m]: [m: baseMessage<'disconnect'>]) {
    console.log('do something on disconnect');
    // what to do?
}
export function lastPositionHandler(state: iRemoteStore, [{userId, payload }]: [m: baseMessage<'last-xy'>]) {
    return assocIn(state, <any>['users', userId, 'position'], <any>payload);
}