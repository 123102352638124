import { dispatcher } from '../../store/main'
import { ctrlKey } from '../../utils/misc'
import { Icon } from '../icon'
import { ActionMenu } from './action-menu'

export const SettingsMenu = () => {
    return <ActionMenu menu='misc' ico='menu/misc' tag='settings'>
        <div className='col-actions'>
            <div className='action-item' onClick={dispatcher(':actions/undo')}>
                <Icon ico='misc/undo' />
                <div className='desc'><span>Undo</span><span className='key-combo'>{ctrlKey} + Z</span></div>
            </div>

            <div className='action-item' onClick={dispatcher(':actions/redo')}>
                <Icon ico='misc/redo' />
                <div className='desc'><span>Redo</span><span className='key-combo'>{ctrlKey} + Shift + Z</span></div>
            </div>

            <div className='action-item' onClick={dispatcher(':actions/clear-canvas')}>
                <Icon ico='misc/clear' />
                <div className='desc'><span>Clear Canvas</span><span className='key-combo'>{ctrlKey} + K</span></div>
            </div>

            <div className='action-item' onClick={() => alert('needs implementation')}>
                <Icon ico='misc/delete' />
                <div className='desc'><span>Delete Project</span><span className='key-combo'>{ctrlKey} + Shift + N</span></div>
            </div>

            <div className='action-item' onClick={dispatcher(':actions/->initiate-snapshot')}>
                <Icon ico='misc/snapshot' />
                <div className='desc'><span>Snapshot</span><span className='key-combo'>{ctrlKey} + P</span></div>
            </div>

            <div className='action-item' onClick={() => alert('needs implementation')}>
                <Icon ico='misc/preview' />
                <div className='desc'><span>Preview</span></div>
            </div>
        </div>
    </ActionMenu>
}